<template>
  <a href="javascript: void(0);" v-if="isProUI && !disablePreview" @click="loadSymbolPreview()"/>
  <router-link :to="`/symbol/${symbol}`" v-else/>
</template>

<script>
import eventBus from "../../services/events";

export default {
  name: "symbolLink",
  props: ['symbol', 'disablePreview'],
  data () {
    return {
      windowWidth: window.innerWidth
    }
  },
  methods: {
    loadSymbolPreview() {

      eventBus.emit('showChangeableContent', {type: 'symbolPreview', symbol: this.symbol})
    },
  }
}
</script>

<style scoped>

</style>
