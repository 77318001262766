<template>
  <div v-if="assets">
    <div id="header">

      <BackButton :link="'/sectorsflow'"/>
      <h2>{{ assets.sectorName }} &bull; {{ assets.sectorSymbol }}</h2>
    </div>
    <DataGridTable :data="assets.assets" :is-compact="true" :default-sort="sortKey"
                   :default-sort-direction="sortDirection" :heightElements="['header']">
      <template #thead="{thClick, sortKey, sorDirection}">
        <HeadCell v-for="(th, thIndex) in headers"
                  :sortKey="sortKey"
                  :sortDirection="sortDirection"
                  :sortValue="th.sortValue"
                  @sort="thClick(th.sortValue)">
          {{ th.label }}
        </HeadCell>
      </template>
      <template #tbody="{rows}">
        <tr v-for="(row, rowIndex) in rows" :key="`r-${rowIndex}`">
          <td class="symbolTd" style="height: 24px">
            <symbol-link :symbol="row.symbol"/>
            <symbol-logo :symbol="row.symbol" :small="true"/>
            {{ row.symbol }}
          </td>
          <td>
            <symbol-label :symbol="row.tradingData" :price-only="true"/>
          </td>
          <td class="priceChangeContainer"
              :class="{up: row.eods?.['5D'] ? row.eods?.['5D'] > 0 : false, down: row.eods?.['5D'] ? row.eods?.['5D'] < 0 : false}">
            {{ row.eods?.['5D'] ? row.eods?.['5D'] + '%' : '-' }}
          </td>
          <td class="priceChangeContainer"
              :class="{up: row.eods?.['1M'] ? row.eods?.['1M'] > 0 : false, down: row.eods?.['1M'] ? row.eods?.['1M'] < 0 : false}">
            {{ row.eods?.['1M'] ? row.eods?.['1M'] + '%' : '-' }}
          </td>
          <td class="priceChangeContainer"
              :class="{up: row.eods?.['6M'] ? row.eods?.['6M'] > 0 : false, down: row.eods?.['6M'] ? row.eods?.['6M'] < 0 : false}">
            {{ row.eods?.['6M'] ? row.eods?.['6M'] + '%' : '-' }}
          </td>
        </tr>
      </template>
    </DataGridTable>
  </div>
</template>

<script>

import SectorService from '../../services/sectors';
import DataGridTable from "../../components/dataGrid/DataGridTable";
import HeadCell from "../../components/dataGrid/HeadCell";
import SymbolLabel from "../../components/symbols/symbol";
import SymbolLogo from "../../components/symbols/symbolLogo";
import SymbolLink from "../../components/symbols/symbolLink";
import BackButton from "../../components/ui/BackButton";

export default {
  name: "SectorAssetsList",
  components: {BackButton, SymbolLink, SymbolLogo, SymbolLabel, HeadCell, DataGridTable},
  props: ['selectedIndustry'],
  data() {
    return {
      isLoading: true,
      assets: null,
      sortDirection: -1,
      sortKey: 'tradingData.changeP',
      headers: [{
        label: 'Symbol',
        sortKey: 'symbol'
      }, {
        label: 'Price',
        sortKey: 'tradingData.changeP'
      }, {
        label: this.dictionary.trendingStocks.priceChange + ' ' + this.dictionary.chart.periods['5d'],
        sortValue: 'eods.5D'
      },
        {
          label: this.dictionary.trendingStocks.priceChange + ' ' + this.dictionary.chart.periods['1m'],
          sortValue: 'eods.1M'
        },
        {
          label: this.dictionary.trendingStocks.priceChange + ' ' + this.dictionary.chart.periods['6m'],
          sortValue: 'eods.6M'
        },]
    }
  },
  async mounted() {

    await this.getAssetsList();
  },
  methods: {
    async getAssetsList() {

      const symbol = this.$route.params.symbol;
      const sectorData = await SectorService.getSectorIndustryEtfAssetsListBySymbol(symbol);
      this.assets = sectorData;
    }
  }
}
</script>

<style scoped lang="scss">

#header {
  border-bottom: 1px solid var(--notficationBorderColor);
  padding: .5rem;
  display: flex;
  align-items: center;
  gap: .5rem;

  h2, h3 {
    margin: 0;
    padding: 0;
  }

  h3 {
    margin-top: 3px;
  }
}
</style>
