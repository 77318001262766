<template>
  <button @click="$emit('click', value)" v-bind="$attrs" :disabled="disabled">
    <i :class="icon" v-if="icon"/>
    {{ label }}
  </button>
</template>

<script>

import {defineOptions} from "vue";

export default {
  name: "MBPButton",
  props: ['action', 'remove', 'warning', 'outline', 'icon', 'secondary', 'label', 'value', 'icon', 'isLoading', 'disabled'],
  emits: ["click"],
  setup() {
    defineOptions({
      inheritAttrs: true
    })
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">

@import "../../../assets/style/controls";

button {
  @extend .inputBase;
  @extend .buttonActions;

  font-family: inherit;
  font-size: 100%;
  -webkit-appearance: button;
  text-transform: none;
  border-radius: 20px;
  font-weight: 500;

  &.transparent {
    background: none;
    color: var(--textColor);
  }

  &.action {
    background: var(--highlightColor);
    color: #1f1d1e;
  }

  &.danger {
    background: var(--opacityRed);
    color: var(--red);
  }

  &:disabled {
    pointer-events: none;
    opacity: .8;
  }
}
</style>
