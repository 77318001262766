<template>
  <div class="tabs" :class="{round, scroll, border, noBorder, noMargin}">


    <div v-for="(tab, tabIdx) in tabs"
         class="tab"
         :class="{active: activeTab === tab.value}"
         @click="$emit('tabClick', tab.value)">
      <i :class="tab.icon" v-if="tab.icon"/>
      {{tab.label}}
    </div>


    <div style="margin-right: 15px">
      <slot/>
    </div>

  </div>
</template>

<script>
export default {
  name: "Tabs",
  props: ['round', 'scroll', 'border', 'tabs', 'noBorder', 'noMargin', 'activeTab'],
  data () {
    return {
    }
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">

  @import "../../assets/style/general";
</style>
