<template>
  <div class="toolbar" :id="id">
    <slot/>
  </div>
</template>

<script>
export default {
  name: "PageToolbar",
  props: ['id']
}
</script>

<style scoped lang="scss">
.toolbar {
  padding: .5rem;
  box-sizing: border-box;
  border-bottom: 1px solid var(--notficationBorderColor);
  @media(max-width: 660px) {
    overflow-x: auto;
  }
}
</style>
