<template>
  <div>
      <router-view/>
  </div>
</template>

<script>

import eventBus from "../services/events";
export default {
  name: "SectorFlowView",
  data () {
    return {
      compareTo: []
    }
  },
  methods: {
    updateCompareTo(array) {
      this.compareTo = array
    }
  },
  mounted() {
    eventBus.on('updateCompareTo', this.updateCompareTo)
  },
  beforeUnmount() {
    eventBus.off('updateCompareTo', this.updateCompareTo)
  }
}
</script>

<style scoped>

</style>
