<template>
  <div class="chartWrapper" ref="chartWrapper">
    <div class="chart" ref="chart" style="height: 400px; width: 100%;"/>
  </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  name: "SimpleLineChart",
  props: ['series', 'xValues'],
  data() {
    return {
      colors: [
        '#41CC20',
        '#fcc10e',
        '#1fffa7',
        '#ff9b3e',
        '#F875AA',
        '#00A9FF',
        '#ff0062',
        '#ebfa00',
      ]
    }
  },
  mounted() {
    this.draw();
  },
  methods: {
    draw() {

      console.log(this.series)
      console.log(this.$refs.chart)
      if (this.$refs.chart && this.series && this.series.length > 0) {
      console.log(this.series)

        let chart = echarts.init(this.$refs.chart);

        let options = {
          animation: false,
          xAxis: {
            type: 'category',
            data: this.xValues
          },
          yAxis: {
            type: 'value',
            inverse: true
          },
          grid: {
            left: '30px',
            right: '30px',
            height: '360px',
            top: '2%',
            containLabel: true
          },
          series: this.series.map((series, sIndex) => (
              {
                name: `series${sIndex}`,
                type: 'line',
                color: this.colors[sIndex],
                showSymbol: false,
                draggable: false,
                roam: false,
                data: series,
                width: 5,
                lineStyle: {
                  opacity: 1,
                }
              }
          ))
        }

        chart.setOption(options, true);
      }

    }
  }
}
</script>

<style scoped lang="scss">

.chartWrapper {
  height: 400px;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  @media(max-width: 660px) {

    height: 330px;
    padding: 1rem;

    .chart {
    }
  }
}

</style>
