<template>

  <section>
    <transition name="fade">
      <div id="chartSlide" v-if="selectedSector" :dir="uiDirection" :style="{width: frameWidth + 'px'}">
      </div>
    </transition>

    <transition name="slide">
      <div id="chartBound" v-if="selectedSector" :style="{width: frameWidth + 'px'}">
        <i :dir="uiDirection" class="close ri-close-line" @click="selectedSector = null"/>
        <div id="headers">
          <h2>{{ selectedSector.industry }} &bull; {{ selectedSector.sector }}</h2>
          <h3>{{ selectedSector.symbol }}</h3>
        </div>
        <SimpleLineChart :series="[formatChartSeries(selectedSector)]" :key="chartKey"
                         :xValues="['Last rank', 2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20].reverse()"/>
      </div>
    </transition>

    <div id="compareSelection" ref="compareSelection">

      <tabs
          style="margin: 0; padding: 0;"
          :tabs="[{value: 'table', label: 'Table layout'}, {value: 'heatmap', label: 'Heatmap'}]"
          :active-tab="elementDisplay" :scroll="true" :no-border="true"
          @tabClick="elementDisplay = $event;">
        <div id="wrapper" v-if="toCompare.length > 0">
          <MBPButton class="action small" label="Compare selection" @click="comparision = true"/>
          <MBPButton class="danger small" label="Clear selection" @click="comparision = false; resetComparision()"/>
        </div>
      </tabs>


    </div>


    <DataGridTable
        ref="heatmapTable"
        v-if="elementDisplay === 'heatmap'"
        :data="list"
        :default-sort="sortKey"
        :default-sort-direction="sortDirection"
        :is-compact="true"
        :key="heatmapKey"
        :hovered-columns="true"
        :heightElements="['compareSelection']"
        :fixed-col="1">

      <template #thead="{thClick, sortKey, sorDirection}">
        <HeadCell :center="true"
                  :width="thIndex === 0 ? 40 : null"
                  v-for="(th, thIndex) in ['', 'Industry','Last rank', 2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20, 21]">
          {{ thIndex < 3 ? th : th + ' days' }}
        </HeadCell>
      </template>

      <template #tbody="{rows}">
        <tr v-for="(row, rowIndex) in rows" :key="`r-${rowIndex}`"
            v-show="(comparision && toCompare.includes(rowIndex)) || !comparision">
          <td>
            <div style="position: relative; cursor: pointer">
              <label :for="row.symbol"
                     style="position: absolute; left: 0; padding: 10px; margin: -10px; top: 0; width: 100%; height: 100%; cursor:pointer; display: flex; align-items: center; justify-content: center">
                <input type="checkbox" v-model="toCompare" :value="rowIndex" :id="row.symbol" name="compare"/>
              </label>
            </div>
          </td>
          <td style="z-index: 5">
            <div class="fullLink">
              <router-link :to="`/sectorsflow/${row.symbol}`"/>
              {{ row.industry }} ({{ row.symbol }})
            </div>
          </td>
          <td class="rankCell" v-for="day in [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21]">
            <div class="rank">{{ row[`rank${day}`] }}</div>
            <div class="bg" :style="setRankOpacity(row[`rank${day}`], list.length)"
                 :class="setRankClass(row[`rank${day}`], list.length)"/>
          </td>
        </tr>
      </template>
    </DataGridTable>

    <DataGridTable
        v-if="elementDisplay === 'table'"
        :data="list"
        :default-sort="sortKey"
        :default-sort-direction="sortDirection"
        :is-compact="true"
        :hovered-columns="true"
        :fixed-col="1">

      <template #thead="{thClick, sortKey, sorDirection}">
        <HeadCell v-for="(th, thIndex) in headers"
                  :sortKey="sortKey"
                  :width="thIndex === 0 ? 40 : null"
                  :center="thIndex > 0"
                  :sortDirection="sortDirection"
                  :sortValue="th.sortValue"
                  @sort="thClick(th.sortValue)">
          {{ th.label }}
        </HeadCell>
      </template>

      <template #tbody="{rows}">
        <tr v-for="(row, rowIndex) in rows" :key="`rt-${rowIndex}`"
            :class="{highlightRow: toCompare.includes(rowIndex) && !comparision}"
            v-show="(comparision && toCompare.includes(rowIndex)) || !comparision">
          <td style="z-index: 5; background: var(--bgColor)">
            <div style="position: relative; cursor: pointer;">
              <label :for="row.symbol"
                     style="position: absolute; left: 0; padding: 10px; margin: -10px; top: 0; width: 100%; height: 100%; cursor:pointer; display: flex; align-items: center; justify-content: center">
                <input type="checkbox" v-model="toCompare" :value="rowIndex" :id="row.symbol" name="compare"/>
              </label>
            </div>
          </td>
          <td style="z-index: 5">
            <div class="fullLink">
              <router-link :to="`/sectorsflow/${row.symbol}`"/>
              {{ row.industry }} ({{ row.symbol }})
            </div>
          </td>
          <td class="center bidi">
            <symbol-label :key="`s-${row.symbol}`"
                          :hide-pre-post="true"
                          :symbol="row.tradingData" :price-only="true" :always-on="true"/>
          </td>
          <td class="center" @click="selectedSector = row; chartKey++;">
            <i class="ri-line-chart-line chartLink"/>
          </td>
          <!--        <td class="center bidi priceChangeContainer" :class="{up: row.rank5Diff > 0, down: row.rank5Diff < 0}">-->
          <td class="center bidi priceChangeContainer">
            {{ row.todayRank }} <span
              :style="row.rank5 - row.todayRank > 0 ? {color: 'var(--green)'} : {color: 'var(--red)'}">({{
              (row.rank5 - row.todayRank) > 0 ? '+' : ''
            }} {{ row.rank5 - row.todayRank }})</span>
            <!--          ({{row.rank5Diff > 0 ? '+' : ''}}{{row.rank5Diff}}) ({{row.rankChange}})-->
          </td>


          <td class="center bidi rankCell" v-for="day of [5, 10, 15, 20]">

            <div class="rank">{{ row[`rank${day}`] }}</div>
            <div class="bg" :style="setRankOpacity(row[`rank${day}`], list.length, 5)"
                 :class="setRankClass(row[`rank${day}`], list.length)"/>

            <!--            {{ row[`rank${day}`] }}-->
          </td>
        </tr>
      </template>
    </DataGridTable>
  </section>

</template>

<script>

import DataGridTable from "../../components/dataGrid/DataGridTable";
import HeadCell from "../../components/dataGrid/HeadCell";

import SectorService from '../../services/sectors';
import SimpleLineChart from "../../components/charts/SimpleLineChart";
import SymbolLabel from "../../components/symbols/symbol";

import eventBus from "../../services/events";
import MBPButton from "../../components/ui/controls/MBPButton";
import Tabs from "../../components/ui/Tabs";


export default {
  name: "SectorFlowTable",
  components: {Tabs, MBPButton, SymbolLabel, SimpleLineChart, HeadCell, DataGridTable},
  data() {
    return {
      comparision: false,
      toCompare: [],
      frameWidth: 0,
      chartKey: 0,
      displayTerm: 'short',
      heatmapKey: 0,
      sortDirection: 1,
      sortKey: 'todayRank',
      elementDisplay: 'table',
      selectedSector: null,
      shortTerm: [],
      mediumTerm: [],
      shortTermHeatmap: [],
      headers: [{
        label: ''
      },
        {
          label: 'Industry',
          sortValue: 'symbol'
        }, {
          label: 'Price',
          sortValue: 'tradingData.changeP'
        }, {
          label: 'History',
        }, {
          label: `Last rank`,
          sortValue: 'todayRank'
        }, ...[5, 10, 15, 20].map(day => ({
          label: `${day} days ago`,
          sortValue: `rank${day}`
        }))]
    }
  },
  beforeMount() {

    this.getSectorsList().then().catch();
  },
  mounted() {
    this.$nextTick(() => {

      this.frameWidth = document.querySelector('.view').clientWidth + 5
    });
  },
  watch: {
    // toCompare(newValue, oldValue) {
    //
    //   if (newValue.length > 0 && oldValue.length === 0) {
    //
    //     this.$nextTick(() => {
    //       this.heatmapKey++;
    //     });
    //   } else if (newValue.length === 0 && oldValue.length > 0) {
    //     this.$nextTick(() => {
    //       this.$refs.compareSelection.style.height = '0px';
    //       this.$refs.compareSelection.style.padding = '0 10px'
    //       setTimeout(() => {
    //
    //         this.heatmapKey++;
    //         this.$nextTick(() => {
    //           this.$refs.heatmapTable.handleResize()
    //         })
    //       }, 410)
    //     })
    //   }
    // }
  },
  computed: {
    list() {
      //
      // if (this.comparision) {
      //
      //   return this.shortTerm.filter((_, index) => this.toCompare.includes(index));
      // }
      return this.displayTerm === 'short' ? this.shortTerm : this.mediumTerm;
    }
  },
  methods: {
    resetComparision() {

      this.toCompare = [];
    },
    setRankOpacity(rank, ranksLength, limit = null) {
      const middle = ranksLength / 2;
      if (rank <= middle) {

        if (!limit) {
          return {opacity: (1 - (rank / middle)) - 0.03};
        } else {
          return {opacity: ((1 - ((rank - 1) / limit)) - 0.1)};
        }
      } else {
        if (!limit) {
          return {opacity: ((rank - middle) / ranksLength) - 0.03};
        } else {

          const bottomFive = ranksLength - 5;
          if (rank >= bottomFive) {
            return {opacity: ((rank - bottomFive) / limit) - 0.1};
          } else {
            return {opacity: 0}
          }
        }
      }
    },
    setRankClass(rank, ranksLength) {

      const middle = ranksLength / 2;
      return rank <= middle ? 'up' : 'down';
    },
    showList(symbol) {
      eventBus.emit('showChangeableContent', {type: 'industryEtfStocks', symbol})
    },
    formatChartSeries(sector) {

      return [sector.todayRank, ...[2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21].map(day => (sector[`rank${day}`]))].reverse()
    },
    async getSectorsList() {

      const {shortTerm, mediumTerm, shortTermHeatmap} = await SectorService.getSectorsMoneyFlow();
      this.shortTerm = shortTerm;
      this.mediumTerm = mediumTerm;
      this.shortTermHeatmap = shortTermHeatmap;
    }
  }
}
</script>

<style scoped lang="scss">

section {
  overflow: hidden;
}

#headers {
  padding: 1.5rem 2rem 0;

  h2 {
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  h3 {
    margin-top: 0;
    padding-top: 0;
  }
}

.rankCell {
  position: relative;
  text-align: center;

  .rank {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    font-weight: bold;
    font-size: 16px;
  }

  .bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;

    &.up {
      background: var(--green);
    }

    &.down {
      background: var(--red);
    }
  }
}

#chartSlide {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  right: 0;
  z-index: 99;

  &[dir="rtl"] {
    right: unset;
    left: 0;
  }
}

#chartBound {
  display: block;
  bottom: 0;
  //margin-top: calc(100vh - 500px - 2rem);
  height: 500px;
  width: 960px;
  background: var(--bgColor);
  position: fixed;
  z-index: 100;
}

.chartLink {
  cursor: pointer;
  padding: 2px 5px;
  border-radius: 4px;
  background: var(--mbpGreenOpacity);
  color: var(--mbpGreen);
  text-decoration: none;

  * {
    color: var(--mbpGreen);
    text-decoration: none;
  }
}

.close {
  background: var(--tableHoverColorOpacity);
  border-radius: 50%;
  padding: 10px;
  margin: 1rem 2rem;
  cursor: pointer;
  position: fixed;
  font-size: 22px;
  font-weight: bold;
  right: 0;
  transition: all .2s ease-in;

  &:hover {
    background: var(--tableHoverColor);
  }

  &[dir="rtl"] {
    right: unset;
    left: 0;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.slide-enter-active, .slide-leave-active {
  transition: all .3s ease-in;
}

.slide-enter {
  margin-top: 100vh;
  transform: translateY(100%) !important;
}

.slide-enter-to {
  margin-top: 0;
  transform: translateY(0%);
}

.slide-leave {
  transform: translateY(0%);
}

.slide-leave-to {
  transform: translateY(100%) !important;
}

#compareSelection {
  border-bottom: 1px solid var(--notficationBorderColor);
  display: flex;
  align-items: center;
  gap: .5rem;

  #wrapper {
    margin-left: 1rem;
    display: flex;
    align-items: center;
    gap: .5rem;
    padding: 4px 0;
  }
}


</style>
