<template>
  <div class="backButton">
    <router-link :to="link" v-if="link"/>
    <i class="ri-arrow-left-line"/> Back
  </div>
</template>

<script>
export default {
  name: "BackButton",
  props: ['link']
}
</script>

<style scoped lang="scss">
  .backButton {
    position: relative;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 10px;
    &:hover {
      background: var(--tableHoverColorOpacity);
    }

    a{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
</style>
