export default {
    navBar: {
        home: 'Home',
        myWatchlist: 'My watchlist',
        calendars: 'Calendars',
        settings: 'Settings',
        socialBuzz: 'Trending stocks',
        sectors: 'Sectors performance',
        stocksFeed: 'My watchlist news',
        macroFeed: 'Market news',
        top10: 'Top 10',
        dividends: 'Dividends',
        top10Pro: 'Market performance',
        indices: 'Top indices',
        institutional: 'Institutional holdings',
        contactUs: 'Contact us',
        termsOfUse: 'Terms of use',
        selectLanguage: 'Select system\'s language',
        heatmap: 'Heatmap',
        new: 'New',
        soon: 'Soon'
    },
    contactUs: {
        title: 'Contact us',
        text: 'We are committed to providing you with the most relevant and high-quality information in the shortest possible time. We welcome any ideas, suggestions for improvement, or bug reports you may have.',
        name: 'Name',
        email: 'Email',
        message: 'Message',
        privacy: 'Your privacy is important to us. The information is intended to answer your request and will not be transferred to a third party or used to send spam.'
    },
    errors: {
        noContentMessage: 'Please write down a message',
        invalidEmail: 'Please type a valid email address',
        emptyCalendar: 'There are no events for the selected period',
        emptyWatchlist: 'The watchlist is empty'
    },
    login: {
        trialEnded: {
            title: 'Your Marketbit.pro trial has ended',
            explain: (price) => `<b>Thank you for trying out our platform!</b>
              <br/>To make sure you don\'t miss out on great market opportunities start your premium account for only $${price}/mo.<br/>
              If you have any issues or question please feel free to reach out <a class="mbpLink" href="mailto:hello@marketbit.pro">hello@marketbit.pro</a>.`,
            signupButton: 'Signup for subscription'
        }
    },
    languages: {
        he: 'Hebrew',
        en: 'English'
    },
    watchlist: {
        numberOfSymbols: 'Number of symbols'
    },
    filters: {
        sector: 'Sector',
        industry: 'Industry',
        sectors: 'Sectors',
        industries: 'Industries',
        searchType: '(You can type and search)',
        pe: 'P/E',
        marketCap: 'Market cap.',
        priceChange: 'Price change',
        moreThan: 'More than',
        lessThan: 'Less then',
        andMore: 'and above',
        andLess: 'and less',
        years: 'years',
        typeAndEnter: 'Free typing (press Enter to end)',
        dividendConsecutiveYears: 'Consecutive years of div. payout',
        dividendPayoutRatio: 'Dividend payout ratio',
        dividendYield: 'Dividend yield',
        dividendFrequency: 'Dividend frequency',
        dividendFrequencyList: [{
            label: 'Quarterly',
            value: 'quarterly'
        }, {
            label: 'Semi-yearly',
            value: 'semiYearly'
        }, {
            label: 'Yearly',
            value: 'yearly'
        }],
        marketCapsList: [
            {
                label: 'Micro-Cap ( < $250M)',
                value: 'microCap'
            },
            {
                label: 'Small-Cap ($250M - $2B)',
                value: 'smallCap'
            },
            {
                label: 'Mid-Cap ($2B - $10B)',
                value: 'midCap'
            },
            {
                label: 'Large-Cap ($10B - $200B)',
                value: 'largeCap'
            },
            {
                label: 'Mega-Cap ( > $200B)',
                value: 'megaCap'
            }
        ],
        select: 'Select',
        seeNews: 'See filtered news',
        newFilter: 'Back to filter',
        resetFilter: 'Reset filter',
        applyFilter: 'Apply filter',
        results: 'Results',
        fount: 'found',
        symbol: 'Symbol',
        periods: [
            {
                value: '1D',
                label: '1D'
            },
            {
                value: '5D',
                label: '5D'
            },
            {
                value: '1M',
                label: '1M'
            },
            {
                value: '6M',
                label: '6M'
            },
            {
                value: 'YTD',
                label: 'YTD'
            },
            {
                value: '1Y',
                label: '1Y'
            },
            {
                value: '3Y',
                label: '3Y'
            }
        ],
    },
    search: {
        tabs: {
            freeSearch: 'Free search',
            filter: 'Filter by parameters',
            trending: 'Trending symbols'
        },
        filter: {
            name: 'Name',
            price: 'Price',
            mustSelectASector: 'You must select a sector or an industry. You can search an industry by typing.',
            sectorSelection: 'Sector selection',
            industrySelection: 'Industries selection (you can type and search)',
            marketCapSelection: 'Market Cap. selection',
            priceChange: 'Price change',
            moreThan: 'More than',
            lessThan: 'Less than',
            search: 'Filter',
            pe: 'P/E',
            seeNews: 'See filtered news',
            newFilter: 'Back to filter',
            resetFilter: 'Reset filter',
            results: 'Results',
            fount: 'found',
            symbol: 'Symbol',
            periods: [
                {
                    value: '1D',
                    label: '1D'
                },
                {
                    value: '5D',
                    label: '5D'
                },
                {
                    value: '1M',
                    label: '1M'
                },
                {
                    value: '6M',
                    label: '6M'
                },
                {
                    value: 'YTD',
                    label: 'YTD'
                },
                {
                    value: '1Y',
                    label: '1Y'
                },
                {
                    value: '3Y',
                    label: '3Y'
                }
            ],
            sector: 'Sector',
            industry: 'Industry',
            marketCap: 'Market cap.',
            marketCapRangeValues: [
                {
                    value: '<500m',
                    label: 'Under $500M'
                },
                {
                    value: '500m-1b',
                    label: '$500M-$1B'
                },
                {
                    value: '1b-10b',
                    label: '$1B-$10B'
                },
                {
                    value: '10b-200b',
                    label: '$10B-$200B'
                },
                {
                    value: '>200b',
                    label: 'Above $200B'
                }
            ],
            marketCapValues: [
                {
                    value: '<10m',
                    label: 'Under $10M'
                },
                {
                    value: '>10m',
                    label: 'Above $10M'
                },
                {
                    value: '>100m',
                    label: 'Above $100m'
                },
                {
                    value: '>1b',
                    label: 'Above $1B'
                },
                {
                    value: '>10b',
                    label: 'Above $10B'
                },
                {
                    value: '>100b',
                    label: 'Above $100B'
                }
            ],
            performance: 'Performance',
        }
    },
    tags: {
        quickLook: 'Quick look',
        close: 'Close',
        topGainers: 'Top gainers',
        topActive: 'Top active',
        topLosers: 'Top losers',
        moreSymbols: 'More',
        tradingHalt: 'Trading halt',
        tradingResumption: 'Trading resumption',
        signal: 'Signal',
        trade: 'Trade',
        taReview: 'Technical review',
        moreEvents: 'More events',
        hideSymbols: 'Hide',
        insiders: 'Insider\'s trade',
        pressRelease: 'Press release',
        shouldKnowNotificationTitle: 'You should know',
        marketOpen: 'The market is open',
        marketClose: 'The market is closed',
        preMarket: 'Pre-market',
        postMarket: 'Post-market'
    },
    buttons: {
        continueToApp: 'Back to Marketbit.pro',
        close: 'Close',
        send: 'Send',
        en: 'English',
        he: 'Hebrew',
        filterStocks: 'Filter stocks',
        editColumns: 'Edit columns',
        seeFullDetails: 'See full details',
        showMoreInfo: 'Show more info',
        hideMoreInfo: 'Hide more info',
        approveTerms: 'I approve the terms of service',
        moveToPortfolio: 'Click here to add symbols',
        addToPortfolio: 'Add to watchlist',
        removeFromPortfolio: 'Remove from watchlist',
        addToPortfolioShort: 'Add',
        removeFromPortfolioShort: 'Remove',
        readMore: 'Read more',
        readLess: 'Read less'
    },
    feeds: {
        viewImage: 'View image',
        viewLink: 'View link',
        stocksFeed: 'My watchlist news',
        macroFeed: 'Market news',
        backToPortfolio: 'Back to watchlist',
        filters: {
            all: 'All',
            tradesAndSignals: 'Trades & signals',
            priceTargets: 'Analysts',
            pr: 'Press release',
            hedgeFunds: 'Hedge funds',
            earnings: 'Earnings',
            sec: 'SEC',
            financialMedia: 'Financial media',
            analysts: 'BZ wire'
        }
    },
    tables: {
        symbol: 'Symbol',
        '1d' : 'Day %',
        '5d': '5D %',
        '1m': '1M %',
        '6m' : '6M %',
        'q' : 'Quarter %',
        ytd: 'YTD %',
        '1y' : '1Y %',
        '3y' : '3Y %',
        '5y' : '5Y %',
        sector: 'Sector',
        industry: 'Industry',
        marketCap: 'Market cap.',
        pe: 'P\\E',
        nextDividendExDate: 'Next div date',
        datePublished: 'Date published',
        lastUpdate: 'Date updated',
        numOfFollowups: '# of followups',
    },
    trades: {
        percentChange: '% change',
        percentChangeTooltip: 'Stock\'s price percent change since a trade was published',
        since: 'since',
        gained: 'gained',
        lost: 'lost',
        dateAdded: 'Publishing date',
        lastUpdate: 'Update date',
        numberOfFollowups: '# followups',
        published: 'Published',
        updated: 'Updated',
        daysAgo: 'days ago',
        change: 'Cng. %',
        week1: '1 week',
        week2: '2 weeks',
        week3: '3 weeks',
        week4: '4 weeks',
        week5: '5 weeks',
        week6: '6 weeks',
        tradeHasFollowups: (num) => `This trade has <span class="highlight">${num}</span> followups.`,
        sinceTradeWasPublished: (symbol) => `Since this trade was published, <b>${ symbol }</b>'s price`,
    },
    messages: {
        followup: 'Trade follow up',
        enlargeImage: 'Click to enlarge image',
        welcomeBanner: 'Weclome to Marketbit.pro!',
        languageBanner: 'Please choose system\'s language:',
        topMktCap: 'This page refers to companies with a +$50M market cap and a volume of +150K',
        topVolume: 'This page refers to companies with a volume of 150K and above',
        peersExpl: (symbol) => `Stocks that have been evaluated according to a number of factors and have a market cap similar to ${symbol}`,
        shortInstructions: 'Marketbit.pro brings you real-time stock market news and events automatically. To create your own news feed easily add symbols to your watchlist. You can add and remove symbols at any time.',
        contactMessageSentTitle: 'Your message has been sent!',
        contactUsThankYou: 'Thank you for your message, we will respond shortly',
        watchlist: 'Search for symbols and add them to your watchlist. Building a watchlist will allow you to receive news and events relevant to you before everyone else.',
        trendingStocks: 'Highly mentioned stocks on social media. Changes in the number of mentions of a stock can indicate a growing trend on the social media platforms.',
        exDiv: 'On the ex-dividend date, the stock price falls by the amount of the dividend, and only those who own the stock on the ex-dividend date receive the dividend.',
        sectors: 'View the performance of the various market sectors. By clicking on a sector name you can view the symbols that it includes and sort them by different parameters.'
    },
    symbolSearch: {
        frequentlySearched: 'Frequently searched symbols',
        inputLabel: 'Search symbols',
        inputLabelNote: 'You can search a number of symbols or equity names seperated by the space key.',
        noResults: 'No results were found for'
    },
    settingsPage: {
        titles: {
            title: 'Settings',
            notificationSettings: 'Notification settings',
            userSettings: 'User settings',
            systemSettings: 'System settings',
            dangerZone: 'Danger zone',
            subscriptionSettings: 'Subscription settings',
            languageSettings: 'Language settings',
            systemLanguage: 'Default system\'s language',
            newsLanguages: 'News languages',
            verifyClosingOfAccount: 'Approve account closing'
        },
        buttons: {
            saveLanguageSettings: 'Save language settings',
            saveNotifications: 'Save notification settings',
            saveAccount: 'Save account settings',
            closeAccount: 'Close account',
            logout: 'Logout this account',
            subscribeNow: 'Subscribe to Marketbit.pro premium now',
            subscribe: 'Subscribe',
            approveClosingOfAccount: 'I understand, close the account'
        },
        tabs: {
            account: 'Account',
            notifications: 'Notifications',
        },
        notes: {
            dataUpdate: 'Data was updated',
            updatePassword: 'Please update password',
            account: 'Set you account details and subscription. You can cancel your account at any time.',
            notifications: 'Set email notifications. You can get it in real-time, as a summary at a fixed time (if any exists) or shut it off.',
            googleAuth: 'You have signed up to Marketbit.pro with your Google account. If you change your Email address you will not be able to login using Google again.',
            verifyClosingOfAccount: 'Please approve the closing of this account. You will not be able to receive any notifications or other services, login to this account and any payments will cease.',
            expiration: (date, days) => {
                return `Your trial account will end in <b>${days}</b> days on ${date}.<br/>Signup for Marketbit.pro premium plan for only $14.99/mo - A ONE TIME LAUNCH PRICE!`
            }
        },
        account: {
            labels: {
                systemTheme: 'System color',
                lightMode: 'Light mode',
                darkMode: 'Dark mode',
                firstName: 'First name',
                lsatName: 'Last name',
                password: 'New password',
                email: 'Email',
                subscriptionDetails: 'Subscription details',
                cancelSubscription: 'Cancel subscription',
                updatePaymentCard: 'Update payment method',
                subscriptionPrice: 'Subscription price',
                nextPayment: 'Next payment date'
            },
        },
        notificationLabels: {
            portfolio: 'Portfolio notifications',
            breakingNews: 'Breaking news',
            vip: 'VIP',
            oilGas: 'Oil & gas',
            banks: 'Banks',
            commodities: 'Commodities',
            econData: 'Economic data',
            emailNotifications: 'Email notifications',
            browserNotifications: 'Browser notifications',
            soundNotifications: 'Sound notifications'
        },
        notificationPeriodLabels: {
            none: 'None',
            realTime: 'Real-time',
            five_min: 'Every 5 minutes',
            fifteen_min: 'Every 15 minutes',
            thirty_min: 'Every 30 minutes',
            fortyfive_min: 'Every 45 minutes',
            sixty_min: 'Every hour'
        }
    },
    symbolPage: {
        moreStocks: 'Stock you maybe interested in',
        findMoreStocks: 'Find interesting stocks',
        findMoreStocksText: (symbol) => `Find interesting stocks related to <b>${symbol}</b> based on perfromance and a similar market cap.`,
        clickToFindMoreStocks: 'Click here to find more stocks',
        performanceOverTime: 'Price change over time',
        epsNote: 'The difference between the EPS, as calculated on our stock page, as well as on most other financial websites, may be due to the fact that the stock page refers to the accounting profit, which includes one-time expenses and revenues that are not representative in the opinion of the company\'s management. While the company\'s performance usually shows the "adjusted profit", that is, without the one-time expenses and revenues, as defined by the company\'s management',
        pe: 'P/E',
        eps: 'EPS',
        nextEarnings: 'Next earnings date',
        releaseTime: 'Release time',
        estimatedEps: 'Estimated EPS',
        nextDividend: 'Next dividend',
        exDate: 'Ex-Date',
        paymentDate: 'Payment date',
        nextSplitDate: 'Next split date',
        splitRatio: 'Split ratio',
        name: 'Company name',
        about: 'About the company',
        marketCap: 'Market cap',
        volume: 'Volume',
        unusualVolume: 'Above avg.',
        avgVolume: 'Avg. volume',
        price: 'Price',
        symbol: 'Symbol',
        sector: 'Sector',
        industry: 'Industry',
        sharesOutstanding: 'Shares outstanding',
        dailyRange: 'Daily range',
        fiftyTwoWeeks: '52 weeks',
        previousClose: 'Previous close',
        TYRevenueChange: '3Y revenue change',
        TYEPSChange: '3Y EPS change',
        tabs: {
            overview: 'Overview',
            news: 'News',
            chart: 'Chart',
            financials: 'Financials',
            performance: 'Performance',
            insiders: 'Insiders'
        }
    },
    chart: {
        periods: {
            '1d': '1D',
            '5d': '5D',
            '1m': '1M',
            '6m': '6M',
            'ytd': 'YTD',
            '1y': '1Y',
            '3y': '3Y',
            '5y': '5Y',
            'max': 'Max'
        },
        ma: 'Moving Averages',
        indexCompare: 'Compare index',
        tooltip: {
            change: 'Change',
            volume: 'V',
            open: 'O',
            close: 'C',
            high: 'H',
            low: 'L',
            price: 'Price'
        }
    },
    financials: {
        note: 'The monthly filter displays the change from the previous quarter, while the annual filter displays the change from the same quarter in the previous year.',
        periodChange: 'Change %',
        yoy: 'YoY',
        quarterly: 'Quarterly',
        incomeStatement: 'Income statement',
        balanceSheet: 'Balance sheet',
        cashFlowStatement: 'Cash flow statement',
        revenue: 'Revenue',
        operatingExpenses: 'Operating expenses',
        netIncome: 'Net income',
        netProfitMargin: 'Profit margin',
        eps: 'EPS',
        ebitda: 'EBITDA',
        cashAndShortTermInvestment: 'Cash and short-term investment',
        totalAssets: 'Total assets',
        totalLiabilities: 'Total liabilities',
        totalEquity: 'Total equity',
        operatingCashFlow: 'Operating cash flow',
        netCashFromInvesting: 'Net cash from investing',
        netCashFinancing: 'Net cash from financing',
        netChangeInCash: 'Net cash change',
        freeCashFlow: 'Free cash flow'
    },
    insiders: {
        note: 'Insider stock transction can signal either positive OR negative signal. ' +
            'Insider BUYING can signal confidence in the company\'s future prospects. ' +
            'Insider SELLING (significant amount)  can signal or interpreted a loss of confidence.',
        filingDate: 'Filing date',
        owner: 'Name',
        role: 'Role',
        date: 'Date',
        transaction: 'Transaction',
        cost: 'Cost',
        shares: '# shares',
        value: 'Total cost',
        ownedShares: 'Total shares',
        ownershipWorth: 'Total ownership worth',
        ownershipRatio: 'Ownership ratio',
        totalBuyTransactions: '# buy transactions',
        totalSellTransactions: '# sell transactions',
        clickToWatchLink: 'Click to see the filing',
        link: 'Link',
        reportedAtDate: 'Filing date',
        transactions: {
            sell: 'Sell',
            buy: 'Buy',
            option: 'Options exercise',
            award: 'Award',
            gift: 'Gift',
            tax: 'Tax/exercise payment'
        }
    },
    trendingStocks: {
        symbol: 'Symbol',
        name: 'Name',
        price: 'Price',
        sectorIndustry: 'Industry',
        ratingChange: 'Rating change',
        numberOfMentions: 'Num. of mentions',
        priceChange: 'Price change %',
        toTheFullList: 'See the full list'
    },
    dailyBrief: {
        title: 'You should know today',
        explanation: 'Intra-day and pre-market information you should know about',
        indices: {
            title: 'Top indices',
            usa: 'USA',
            europe: 'Europe',
            commodities: 'Commodities',
            asia: 'Asia',
            crypto: 'Crypto'
        },
        selectedEarnings: {
            weeklyEarningsTitle: 'Selected earnings this week',
            dailyEarningsTitle: 'Selected earnings today',
            seeAllEarnings: 'Click to see all earnings',
            symbol: 'Symbol',
            reportTime: 'Report time'
        },
        selectedEvents: {
            title: 'Selected daily events',
            name: 'Name',
            datetime: 'Date & time',
            prev: 'Prev.',
            est: 'Est.',
            weeklyEventsTitle: 'Selected weekly events',
            dailyEventsTitle: 'Selected daily events',
            noEvents: 'No events to see',
            date: 'Date',
            time: 'Time'
        }
    },
    topTen: {
        proTitle: 'Market performance',
        title: 'Top 10 stocks',
        gainers: 'Gainers',
        losers: 'Losers',
        active: 'Active',
        nasdaq: 'Nasdaq',
        sp: 'S&P500',
        market: 'Entire market',
        microCap: 'Micro-Cap ( < $250M)',
        smallCap: 'Small-Cap ($250M - $2B)',
        midCap: 'Mid-Cap ($2B - $10B)',
        largeCap: 'Large-Cap ($10B - $200B)',
        megaCap: 'Mega-Cap ( > $200B)',
        sectors:
            {
                'Communication Services': 'Communication Services',
                'Consumer Cyclical': 'Consumer Cyclical',
                'Energy': 'Energy',
                'Financial Services': 'Financial Services',
                'Real Estate': 'Real Estate',
                'Technology': 'Technology',
                'Basic Materials': 'Basic Materials',
                'Industrials': 'Industrials',
                'Utilities': 'Utilities',
                'Consumer Defensive': 'Consumer Defensive',
                'Healthcare': 'Healthcare'
            }

    },
    sectors: {
        title: 'Sectors performance',
        marketValue: 'Mkt value',
        sharesNumber: 'Shares #',
        sectorRatio: '% of sector',
        changeP: 'Change %',
        volume: 'Volume',
        marketCap: 'Market Cap.',
        pe: 'P/E',
        performance: 'Performance over time',
        daily: 'Last daily % change'
    },
    calendars: {
        noData: 'No data was found',
        selection: {
            earnings: 'Earnings',
            splits: 'Splits',
            ipos: 'IPOs',
            dividends: 'Dividends'
        },
        headers: {
            symbol: 'Symbol',
            reportTime: 'Report time',
            eps: 'EPS',
            dividend: 'Dividend',
            exDate: 'Ex-date',
            payableDate: 'Payable date',
            ratio: 'Ratio',
            sharesOffered: 'Shares offered',
            ipoPrice: 'IPO price',
            sharesValue: 'Shares value',
            exchange: 'Exchange'
        },
        dates: {
            lastWeek: 'Last week',
            past7Days: 'Past 7 days',
            yesterday: 'Yesterday',
            today: 'Today',
            tomorrow: 'Tomorrow',
            thisWeek: 'This week',
            next7Days: 'Next 7 days',
            nextWeek: 'Next week',
            thisMonth: 'This month',
            nextMonth: 'Next month'
        },
        labels: {
            all: 'All',
            myWatchlistOnly: 'Watchlist only',
            preMarket: 'Pre-Market',
            afterMarket: 'After-Market'
        }
    },
    dividends: {
        symbol: 'Symbol',
        name: 'Name',
        lastDivAmount: 'Last Div. $',
        lastDivPayoutDate: 'Last div. payout date',
        lastDivExDate: 'Last div. Ex-date',
        nextDivAmount: 'Next div. $',
        nextDivPayoutDate: 'Next div. payout date',
        nextDivExDate: 'Next div. Ex-date',
        consecutiveYears: 'Consecutive years pay',
        consecutiveYearsGrowth: 'Consecutive years growth',
        industry: 'Industry',
        sector: 'Sector',
        marketCap: 'Market cap.',
        oneYearGrowth: '1Y dividend growth',
        threeYearGrowth: '3Y dividend growth',
        fiveYearGrowth: '5Y dividend growth',
        tenYearGrowth: '10Y dividend growth',
        payoutRatio: 'Payout ratio',
        payoutFrequency: 'Payout frequency',
        yield: 'Yield'
    }
}
