const PATH = '/sector';

export default {
    async getSectorsTree() {

        return axios.get(`${PATH}/tree`);
    },
    async getSectorsMoneyFlow() {

        return axios.get(`${PATH}/flow`);
    },
    async getSectorIndustryEtfAssetsListBySymbol(symbol) {

        return axios.get(`${PATH}/${symbol}/assets`)
    }
}
