<template>
  <div class="shortLong" v-if="direction" :class="{long: direction === 'long', short: direction === 'short'}">
    <i :class="{'ri-arrow-right-up-line': direction === 'long', 'ri-arrow-right-down-line': direction === 'short'}"
       class=""/> {{ labels[direction] }}
  </div>
</template>

<script>
export default {
  name: "ShortLongLabel",
  props: ['direction'],
  computed: {
    labels() {

      if (this.systemLanguage === 'en') {
        return {long: 'Long', short: 'Short'};
      } else if (this.systemLanguage === 'he') {
        return {long: 'לונג', short: 'שורט'};
      }

    }
  }
}
</script>

<style scoped lang="scss">

.shortLong {
  padding: 2px 10px;
  gap: 5px;
  border-radius: 4px;
  overflow: hidden;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  i{
    margin: -6px;
  }

  &.short {
    color: var(--red);
    background: var(--opacityRed);
  }

  &.long {
    color: var(--green);
    background: var(--opacityGreen);
  }
}
</style>
